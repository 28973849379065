import React from 'react';
import { Card, CardContent, DollarSign, EarningContainer, InfoContainer } from './AnalyticsCard.styles';
import { Text16Bold, Text22Bold } from '../../utils/Text/Text.styles';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';

const AnalyticsCard = ({ count, earnings, icon, title }) => {
  return (
    <Card>
      {icon}
      <CardContent>
        <Text16Bold>{title}</Text16Bold>
        <InfoContainer>
          <Text22Bold>{count}</Text22Bold>
          <EarningContainer>
            <DollarSign>$</DollarSign>
            <Text22Bold>{(earnings * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}</Text22Bold>
          </EarningContainer>
        </InfoContainer>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
