import { login, verifyEmail } from '../auth/asyncThunks';
import {
  createInfluencerRequestPassword,
  redeemInvitationCode,
  updateBasicInfo,
  uploadAudio,
  uploadPhoto,
  uploadVideo,
} from '../influencerRequest/asyncThunks';
import { changePassword, fetchUserInfo } from './asyncThunks';

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.pending, state => {
    state.pending.fetchUserInfo = true;
  });
  builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
    const { user } = payload;
    state.user = user;
    state.pending.fetchUserInfo = false;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.pending.fetchUserInfo = false;
  });
};

export const loginBuilder = builder => {
  builder.addCase(login.fulfilled, (state, { payload }) => {
    const { user } = payload;
    state.user = user;
  });
};

export const changePasswordBuilder = builder => {
  builder.addCase(changePassword.pending, state => {
    state.pending.changePassword = true;
  });
  builder.addCase(changePassword.fulfilled, state => {
    state.pending.changePassword = false;
  });
  builder.addCase(changePassword.rejected, state => {
    state.pending.changePassword = false;
  });
};

export const verifyEmailBuilder = builder => {
  builder.addCase(verifyEmail.fulfilled, (state, { payload }) => {
    state.user = payload.user;
  });
};

export const createInfluencerRequestPasswordBuilder = builder => {
  builder.addCase(createInfluencerRequestPassword.fulfilled, state => {
    state.user.hasPassword = true;
  });
};

export const redeemInvitationCodeBuilder = builder => {
  builder.addCase(redeemInvitationCode.fulfilled, (state, { payload }) => {
    state.user = payload.user;
  });
};

export const updateBasicInfoBuilder = builder => {
  builder.addCase(updateBasicInfo.fulfilled, (state, { payload }) => {
    state.user = payload.user;
  });
};

export const uploadPhotoBuilder = builder => {
  builder.addCase(uploadPhoto.fulfilled, (state, { payload }) => {
    state.user.status = payload.userStatus;
  });
};

export const uploadVideoBuilder = builder => {
  builder.addCase(uploadVideo.fulfilled, (state, { payload }) => {
    state.user.status = payload.userStatus;
  });
};

export const uploadAudioBuilder = builder => {
  builder.addCase(uploadAudio.fulfilled, (state, { payload }) => {
    state.user.status = payload.userStatus;
  });
};
