import styled from 'styled-components';
import { Text18Light } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  margin-top: 15rem;
  justify-content: center;
  max-width: 72rem;

  @media (max-height: 800px) {
    margin-top: unset;
    align-items: center;
  }

  @media (max-width: 500px) {
    margin: 2rem 0;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  max-width: 72rem;
  overflow: hidden;

  img {
    max-width: 72rem;
    max-height: 100%;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 36rem;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 5.4rem;
  }
`;

export const Subtitle = styled(Text18Light)`
  color: #c4a0ff;
  margin-top: 0.8rem;
  letter-spacing: -0.54px;
  margin-bottom: 4.4rem;
  text-align: center;
`;
