import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';
import { Link } from 'react-router-dom';

export const StyledButton = styled(Button)`
  margin-top: 4.5rem;

  @media only screen and (max-width: 500px) {
    margin-top: auto;
  }
`;

export const DontHaveAccountContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  margin-top: 1.6rem;
  margin-inline: auto;
  margin-bottom: 3rem;
`;

export const RegisterButton = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  color: #c4a0ff;
  font-size: 1.5rem;
  font-weight: 300;
`;

export const ForgotPasswordButton = styled(RegisterButton)`
  align-self: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and (min-width: 500px) {
    flex: unset;
  }
`;
