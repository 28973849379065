import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';

const HomeBarChart = ({ dailyAnalytics }) => {
  const data = useMemo(() => {
    const days = Object.keys(dailyAnalytics);

    return days.map(day => {
      const date = parseISO(day);
      const dayOfWeek = format(date, 'EEE').toUpperCase();
      return {
        name: dayOfWeek,
        Photos: dailyAnalytics[day].photo.earnings * VALUE_OF_ONE_STAR_IN_USD,
        Videos: dailyAnalytics[day].video.earnings * VALUE_OF_ONE_STAR_IN_USD,
        Bundles: dailyAnalytics[day].bundle.earnings * VALUE_OF_ONE_STAR_IN_USD,
        Other: dailyAnalytics[day].other.earnings * VALUE_OF_ONE_STAR_IN_USD,
      };
    });
  }, [dailyAnalytics]);

  const barColors = [
    { leftColor: '#DDCEFF', rightColor: '#DDCEFF' },
    { leftColor: '#AD88EF', rightColor: '#AD88EF' },
    { leftColor: '#7E4FD0', rightColor: '#7E4FD0' },
    { leftColor: '#572E9F', rightColor: '#572E9F' },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid stroke="#1F1632" strokeDasharray="3 3" />
        <defs>
          {barColors.map((color, index) => (
            <linearGradient key={index} id={`colorUv${index}`} x1="0" y1="0" x2="0" y2="100%" spreadMethod="pad">
              <stop offset="0" stopColor={color.leftColor} />
              <stop offset="100%" stopColor={color.rightColor} />
            </linearGradient>
          ))}
        </defs>
        <XAxis dataKey="name" stroke="#1F1632" tick={{ fill: 'white', fontSize: 12 }} />
        <YAxis
          width={35}
          stroke="#1F1632"
          tick={{ fill: 'white', fontSize: 12 }}
          tickFormatter={value => `$${value}`}
        />
        <Legend
          color="white"
          iconType="circle"
          wrapperStyle={{ fontSize: '12px', display: 'flex', justifyContent: 'center' }}
        />
        <Bar dataKey="Other" stackId="a" fill="url(#colorUv3)" />
        <Bar dataKey="Bundles" stackId="a" fill="url(#colorUv2)" />
        <Bar dataKey="Videos" stackId="a" fill="url(#colorUv1)" />
        <Bar dataKey="Photos" stackId="a" radius={[5, 5, 0, 0]} fill="url(#colorUv0)" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HomeBarChart;
