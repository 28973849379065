import styled from 'styled-components';
import { Text15Light } from '../../../components/utils/Text/Text.styles';
import { Button } from '../../../components/utils/Button/Button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 4.4rem;

  @media only screen and (max-width: 500px) {
    padding: 2rem 1.6rem 5rem 1.6rem;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  flex: 1;
  max-width: 42rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
`;

export const NoteText = styled(Text15Light)`
  line-height: 2.2rem;
  opacity: 0.7;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  border-radius: 16px;
  border: 1px solid #532b9a;
  background: linear-gradient(180deg, #552b9d 0%, #3b1e70 100%);
  padding: 0 1.2rem 0 0.6rem;
  box-shadow: none;
  height: 4.4rem;
  margin-top: 4.4rem;
`;
