import React from 'react';
import {
  AnalyticsContainer,
  ChartContainer,
  ChartContent,
  ChartHeader,
  Content,
  Line,
  PageContainer,
  StatisticContainer,
} from './HomePage.styles';
import { Text14Regular, Text18Bold, Text20Bold } from '../../components/utils/Text/Text.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { AnalyticsCard, HomeBarChart, StatisticCard } from '../../components';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';
import { ReactComponent as SafeIcon } from '../../assets/icons/safe.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card-down.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';
import { ReactComponent as VideoRecorderIcon } from '../../assets/icons/video-recorder.svg';
import { ReactComponent as SaleIcon } from '../../assets/icons/sale.svg';
import { ReactComponent as FilmIcon } from '../../assets/icons/film.svg';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';

const HomePage = () => {
  const user = useSelector(selectUser);
  const { earnings, daily, overview } = user.analytics;

  return (
    <PageContainer>
      <Text20Bold>{`Welcome, ${user.name}!`}</Text20Bold>
      <Content>
        <StatisticContainer>
          <StatisticCard
            title="Available Balance"
            icon={<CreditCardIcon />}
            value={(earnings.available * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}
            valuePrefix="$"
          />
          <StatisticCard
            title="Total Balance"
            icon={<WalletIcon color="#BC9EFF" />}
            value={(earnings.balance * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}
            valuePrefix="$"
          />
          <StatisticCard
            title="Lifetime Proceeds"
            icon={<SafeIcon />}
            value={(earnings.lifetime * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}
            valuePrefix="$"
          />
        </StatisticContainer>
        <ChartContainer>
          <ChartHeader>
            <Text18Bold>Overview</Text18Bold>
            <Text14Regular>Last 7 Days</Text14Regular>
          </ChartHeader>
          <ChartContent>
            <HomeBarChart dailyAnalytics={daily} />
            <Line />
            <AnalyticsContainer>
              <AnalyticsCard
                icon={<ImageIcon />}
                title="Photo Sales"
                earnings={overview.photo.earnings}
                count={overview.photo.count}
              />
              <AnalyticsCard
                icon={<VideoRecorderIcon />}
                title="Video Sales"
                earnings={overview.video.earnings}
                count={overview.video.count}
              />
              <AnalyticsCard
                icon={<FilmIcon />}
                title="Bundle Sales"
                earnings={overview.bundle.earnings}
                count={overview.bundle.count}
              />
              <AnalyticsCard
                icon={<SaleIcon />}
                title="Other Sales"
                earnings={overview.other.earnings}
                count={overview.other.count}
              />
            </AnalyticsContainer>
          </ChartContent>
        </ChartContainer>
      </Content>
    </PageContainer>
  );
};

export default HomePage;
