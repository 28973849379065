import styled from 'styled-components';

// ------ Wrapper ------
export const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: linear-gradient(98deg, #130825 0%, #090313 98.24%);
  border: 1px solid #230e45;
  border-radius: 1.6rem;

  overflow: auto;
  min-height: ${props => props.$fullHeight && '64.2rem'};
`;

// ------ Table ------
export const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
`;

// ------ Header ------
export const TableHeader = styled.thead`
  height: 4rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #230e45;
`;

export const TableHeaderRow = styled.tr`
  display: flex;
  flex: 1;
`;

export const TableHeaderItem = styled.th`
  font-size: 1.4rem;
  font-weight: 300;
  display: flex;
  flex: ${props => (props.$isAction ? 'none' : 1)};
  width: ${props => (props.$isAction ? '16rem' : 'auto')};
  align-items: center;
  cursor: ${props => (props.sortable ? 'pointer' : 'default')};
  color: ${props => (props.sorted ? '#49435d80' : '#C99AFF')};
  min-width: 2rem;
  padding-left: 1rem;
  max-width: ${props => (props.$fixedWidth ? `${props.$fixedWidth}rem` : 'none')};

  &:first-of-type {
    padding-left: 1.6rem;
  }
`;

export const ExpandableTableHeaderItem = styled.th`
  width: 5.2rem;
`;

// ------ Body ------
export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  /* overflow-x: scroll; */
`;

export const TableBodyRow = styled.tr`
  display: flex;
  height: 5.6rem;
  background-color: ${props => props.expandedRow && 'red'};
  border-bottom: ${props => (props.expandedRow ? 'none' : '1px solid #230E45')};
  cursor: ${props => (props.expandable || props.$pointer ? 'pointer' : 'default')};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const TableBodyItem = styled.td`
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: ${props => props.$textCenter && 'center'};
  padding-left: 1rem;
  max-width: ${props => (props.$fixedWidth ? `${props.$fixedWidth}rem` : 'none')};

  &:first-of-type {
    padding-left: 1.6rem;
  }
`;

export const TableItemImage = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 1.2rem;
  margin-right: 1.2rem;
`;

export const ActionTableBodyItem = styled(TableBodyItem)`
  width: 16rem;
  flex: none;
`;

// ------ Footer ------
export const TableFooter = styled.div`
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.6rem 2.4rem;
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 500;
`;

// ------ Other ------
export const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Spinner = styled.img`
  height: 10rem;
  object-fit: contain;
`;

export const NoDataText = styled.p`
  color: #49435d;
  font-size: 1.6rem;
`;
