import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/d/withdrawals';

class WithdrawalService {
  fetchWithdrawals = async page => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching withdrawals.');
    }
  };
}

export default new WithdrawalService();
