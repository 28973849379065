import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';
import { Text24Bold } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 2rem 1.6rem 5rem 1.6rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 4.4rem;
  align-items: center;
  width: 42rem;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 5.3rem;
  @media only screen and (max-width: 500px) {
    margin-top: auto;
  }
`;

export const Title = styled(Text24Bold)`
  margin-bottom: 4.4rem;
  text-align: center;
`;
