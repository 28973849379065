import React, { useEffect, useState } from 'react';
import { Input, OnboardingContainer, ProcessingPaymentModal } from '../../../components';
import {
  ButtonContainer,
  Content,
  Form,
  HaveCodeButton,
  StyledButton,
  Subtitle,
  TextContainer,
  Title,
} from './SubscribePage.styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CHECKOUT } from '../../../components/App/routes';
import { selectInfluencerRequestActionsPending } from '../../../store/slices/influencerRequest/slice';
import { redeemInvitationCode } from '../../../store/slices/influencerRequest/asyncThunks';
import { PAYMENT_SESSION_STATUS, USER_ROLE } from '../../../utils/constants';
import { getPaymentSession } from '../../../store/slices/payment/asyncThunk';
import { selectUser, setUserStatus } from '../../../store/slices/user/slice';

export const enterCodeFormSchema = yup
  .object({
    code: yup.string().trim().required('Code is required'),
  })
  .required();

const PAGE_SEARCH_PARAM = 'sid';

const SubscribePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inviteCode, setInviteCode] = useState(false);
  const [error, setError] = useState(null);
  const [showProcessingPaymentModal, setShowProcessingPaymentModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const user = useSelector(selectUser);

  const { redeemInvitationCode: redeemInvitationCodePending } = useSelector(selectInfluencerRequestActionsPending);
  let [searchParams, setSearchParams] = useSearchParams();

  const sessionId = searchParams.get(PAGE_SEARCH_PARAM);

  useEffect(() => {
    let intervalId;
    if (sessionId && user?.id) {
      setShowProcessingPaymentModal(true);
      intervalId = setInterval(() => {
        dispatch(getPaymentSession({ sessionId }))
          .unwrap()
          .then(({ userData, paymentSession }) => {
            if (paymentSession.status === PAYMENT_SESSION_STATUS.FAILED) {
              searchParams.delete(PAGE_SEARCH_PARAM);
              setSearchParams(searchParams);
              setError(paymentSession.error);
              clearInterval(intervalId);
            } else if (paymentSession.status === PAYMENT_SESSION_STATUS.SUCCEEDED) {
              notifyInfo('Payment successfully processed');
              searchParams.delete(PAGE_SEARCH_PARAM);
              setSearchParams(searchParams);
              setPaymentSuccess(true);
              clearInterval(intervalId);
              if (user.role === USER_ROLE.INFLUENCER_REQUEST) {
                dispatch(setUserStatus(userData.status));
              }
            }
          })
          .catch(err => {
            clearInterval(intervalId);
            notifyError(err.message);
          });
      }, 1000);
    }

    return () => intervalId && clearInterval(intervalId);
    // eslint-disable-next-line
  }, [sessionId, user?.id, dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
    delayError: 300,
    resolver: yupResolver(enterCodeFormSchema),
  });

  const onSubmit = async data => {
    const { code } = data;
    dispatch(redeemInvitationCode({ code }))
      .unwrap()
      .then(() => {})
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <OnboardingContainer>
      <Content>
        {inviteCode ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>Enter Invitation Code</Title>
            <Input placeholder="Enter code" {...register('code')} error={errors.code?.message} />
            <ButtonContainer>
              <StyledButton title="Continue" type="submit" isLoading={redeemInvitationCodePending} />
              <HaveCodeButton onClick={() => setInviteCode(false)}>I don't have an invite code</HaveCodeButton>
            </ButtonContainer>
          </Form>
        ) : (
          <>
            <TextContainer>
              <Title>We’re excited to have you join our community of creators.</Title>
              <Subtitle>
                In order to avoid spam we require creators to pay a profile setup fee of $14.99 per month or enter an
                invitation code below.
              </Subtitle>
            </TextContainer>
            <ButtonContainer>
              <StyledButton title="Continue to Payment" onClick={() => navigate(`/${CHECKOUT}`)} />
              <HaveCodeButton onClick={() => setInviteCode(true)}>I have an invite code</HaveCodeButton>
            </ButtonContainer>
          </>
        )}
      </Content>
      <ProcessingPaymentModal
        show={showProcessingPaymentModal}
        setShow={setShowProcessingPaymentModal}
        error={error}
        paymentSuccess={paymentSuccess}
      />
    </OnboardingContainer>
  );
};

export default SubscribePage;
