import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import { fetchWithdrawalsBuilder } from './builder';

const INIT_STATE = {
  fetchWithdrawalsInfo: {
    pending: false,
    data: [],
    total: 0,
  },
};

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchWithdrawalsBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchWithdrawalsInfo = state => state.withdrawalInfo.fetchWithdrawalsInfo;

export default withdrawalSlice.reducer;
