import React, { useMemo } from 'react';
import { CircleText, Container, StepCircle, StepContainer, StepText } from './OnboardingSteps.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { INFLUENCER_REQUEST_STATUS } from '../../utils/constants';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-01.svg';
import { useLocation } from 'react-router-dom';
import { CHECKOUT, ID_VERIFICATION, SUBSCRIBE, UPDATE_BASIC_INFO, UPLOAD_MEDIA } from '../App/routes';

const STEP_INDEX = {
  [INFLUENCER_REQUEST_STATUS.EMAIL_VERIFIED]: 0,
  [INFLUENCER_REQUEST_STATUS.SUBSCRIBED]: 1,
  [INFLUENCER_REQUEST_STATUS.IDENTIFIED]: 2,
  [INFLUENCER_REQUEST_STATUS.ONBOARDED]: 3,
  [INFLUENCER_REQUEST_STATUS.MEDIA_UPLOADED]: 4,
};

const OnboardingSteps = () => {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();

  const STEPS = useMemo(() => {
    return [
      {
        text: 'Subscribe',
        finished: user.status === INFLUENCER_REQUEST_STATUS.COMPLETED || STEP_INDEX[user.status] > 0,
        active: [`/${SUBSCRIBE}`, `/${CHECKOUT}`].includes(pathname),
      },
      {
        text: 'ID Verification',
        finished: user.status === INFLUENCER_REQUEST_STATUS.COMPLETED || STEP_INDEX[user.status] > 1,
        active: pathname === `/${ID_VERIFICATION}`,
      },
      {
        text: 'Personal Info',
        finished: user.status === INFLUENCER_REQUEST_STATUS.COMPLETED || STEP_INDEX[user.status] > 2,
        active: pathname === `/${UPDATE_BASIC_INFO}`,
      },
      {
        text: 'Gallery Upload',
        finished: user.status === INFLUENCER_REQUEST_STATUS.COMPLETED || STEP_INDEX[user.status] > 3,
        active: pathname === `/${UPLOAD_MEDIA}`,
      },
    ];
  }, [user, pathname]);

  return (
    <Container>
      {STEPS.map((step, index) => {
        return (
          <StepContainer key={index}>
            <StepCircle $active={step.active}>
              {step.finished ? <CheckIcon /> : <CircleText $active={step.active}>{index + 1}</CircleText>}
            </StepCircle>
            <StepText $active={step.active}>{step.text}</StepText>
          </StepContainer>
        );
      })}
    </Container>
  );
};

export default OnboardingSteps;
