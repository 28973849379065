import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${({ $hideNavigation }) => ($hideNavigation ? '100%' : '144rem')};
  display: flex;
  margin-inline: auto;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $hideNavigation }) => ($hideNavigation ? '100%' : 'calc(100% - 18.8rem)')};
  margin-left: ${({ $hideNavigation }) => ($hideNavigation ? '0' : '18.8rem')};
  border-right: ${({ $hideNavigation }) => ($hideNavigation ? 'none' : '1px solid #1d1229')};

  @media only screen and (max-width: 1440px) {
    border-right: none;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: unset;
    height: ${({ $hideNavigation }) => ($hideNavigation ? '100%' : 'calc(100% - 5.6rem)')};
  }
`;
