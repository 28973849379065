import React from 'react';
import { OnboardingContainer } from '../../../components';
import { Content, Subtitle, TextContainer, Title } from './InReviewPage.styles';
import { Text36Bold } from '../../../components/utils/Text/Text.styles';

const InReviewPage = () => {
  return (
    <OnboardingContainer>
      <Content>
        <Text36Bold>🎉</Text36Bold>
        <TextContainer>
          <Title>Your profile is in review.</Title>
          <Subtitle>
            Our team will review your application shortly. We will reach out to you with the next steps once the review
            is complete.
          </Subtitle>
        </TextContainer>
      </Content>
    </OnboardingContainer>
  );
};

export default InReviewPage;
