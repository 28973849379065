import React, { useState } from 'react';
import { OnboardingContainer } from '../../../components';
import { StyledButton, Content, ContentContainer, NoteText, TextContainer } from './IdVerificationPage.styles';
import { Text16Bold } from '../../../components/utils/Text/Text.styles';
import { ReactComponent as FaceIdIcon } from '../../../assets/icons/face-id.svg';
import SumsubWebSdk from '@sumsub/websdk-react';
import {
  IDENTITY_VERIFICATION_RESULT,
  IDENTITY_VERIFICATION_STATUS,
  INFLUENCER_REQUEST_STATUS,
} from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError } from '../../../utils/notify';
import { selectUser, setUserStatus } from '../../../store/slices/user/slice';
import { selectInfluencerRequestActionsPending } from '../../../store/slices/influencerRequest/slice';
import { getOrCreateIdVerificationToken } from '../../../store/slices/influencerRequest/asyncThunks';

const IdVerificationPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { getOrCreateIdVerificationToken: getOrCreateIdVerificationTokenPending } = useSelector(
    selectInfluencerRequestActionsPending,
  );
  const [sumSubToken, setSumSubToken] = useState(null);

  const onVerificationStartClick = () => {
    dispatch(getOrCreateIdVerificationToken())
      .unwrap()
      .then(({ token }) => {
        setSumSubToken(token);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };
  return (
    <OnboardingContainer>
      <Content>
        {!sumSubToken ? (
          <ContentContainer>
            <TextContainer>
              <Text16Bold>ID Verification</Text16Bold>
              {!user.sumsub ? (
                <Text16Bold>Please verify your identity below</Text16Bold>
              ) : (
                <NoteText>
                  Thank you for submitting your documents. Your verification is currently in progress. This typically
                  takes a few minutes, but may take longer depending on the volume of requests. Please stay tuned, and
                  we'll notify you as soon as the process is complete.
                </NoteText>
              )}
            </TextContainer>
            {!user.sumsub && (
              <StyledButton
                isLoading={getOrCreateIdVerificationTokenPending}
                icon={<FaceIdIcon />}
                title="Start Verification"
                onClick={onVerificationStartClick}
              />
            )}
          </ContentContainer>
        ) : (
          <SumsubWebSdk
            accessToken={sumSubToken}
            expirationHandler={() => sumSubToken}
            onMessage={(data, payload) => {
              console.log(payload);
              if (data === 'idCheck.onApplicantStatusChanged') {
                const { reviewStatus, reviewResult } = payload;
                if (reviewStatus === IDENTITY_VERIFICATION_STATUS.COMPLETED) {
                  switch (reviewResult.reviewAnswer) {
                    case IDENTITY_VERIFICATION_RESULT.GREEN:
                      dispatch(setUserStatus(INFLUENCER_REQUEST_STATUS.IDENTIFIED));
                      break;
                    case IDENTITY_VERIFICATION_RESULT.YELLOW:
                      break;
                    case IDENTITY_VERIFICATION_RESULT.RED:
                      break;
                    default:
                      break;
                  }
                }
              }
            }}
            onError={data => console.log('onError', data)}
          />
        )}
      </Content>
    </OnboardingContainer>
  );
};

export default IdVerificationPage;
