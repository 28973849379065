import styled from 'styled-components';
import { Text20Bold } from '../../components/utils/Text/Text.styles';

export const PageContainer = styled.div`
  padding: 2.4rem 2.2rem;
`;

export const Title = styled(Text20Bold)`
  letter-spacing: -0.408px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 42rem;

  @media only screen and (max-width: 768px) {
    margin: auto;
  }

  @media only screen and (max-width: 470px) {
    width: 100%;
  }
`;

export const ProfilePhotoAvatar = styled.div`
  width: 14.2rem;
  height: 14.2rem;
  border-radius: 50%;
  overflow: hidden;
  margin-inline: auto;

  img {
    width: 100%;
    object-fit: cover;
  }
`;
